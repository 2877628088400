<template>
    <div>
        <Header :type="type" />
        <div class="admin-manage-wrap">
            <div class="admin-manage-container">
                <div class="container-out">
                    <div class="admin-letter-toolbar">
                        <el-radio
                            v-model="filterType"
                            label="customer user id"
                            border
                        ></el-radio>
                        <el-radio
                            v-model="filterType"
                            label="company id"
                            border
                        ></el-radio>
                        <el-input
                            style="width: auto"
                            v-model="filterUIValue"
                            type="text"
                            placeholder="Type filter value"
                            clearable
                        ></el-input>
                        <div style="width: 24px"></div>
                        <el-button
                            icon="el-icon-search"
                            @click="filterData"
                            :loading="isFetching"
                            circle
                        ></el-button>
                    </div>
                </div>
                <div class="container-in">
                    <el-table
                        v-loading="isFetching"
                        ref="multipleTable"
                        :data="mailData"
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="() => {}"
                    >
                        <el-table-column label="Company Avatar" align="center">
                            <template slot-scope="scope">
                                <img
                                    class="avatar-img"
                                    :style="{
                                        backgroundImage: `url(${scope.row.receiveSellerProfile.avatarUrl})`,
                                    }"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column label="Company" align="center">
                            <template slot-scope="scope">
                                id: {{ scope.row.receiveUser._id
                                }}<br />
                                taxId: {{ scope.row.receiveSellerProfile.taxId
                                }}<br />
                                name:
                                {{ scope.row.receiveSellerProfile.companyName
                                }}<br />
                                email:
                                {{ scope.row.receiveSellerProfile.email }}<br />
                            </template>
                        </el-table-column>
                        <el-table-column label="Customer" align="center"
                            ><template slot-scope="scope">
                                id: {{ scope.row.senderUser._id }}
                                <br />
                                account: {{ scope.row.senderUser.account }}
                                <br />
                                nickname: {{ scope.row.senderUser.nickname }}
                                <br />
                                email: {{ scope.row.senderUser.email }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Email Content" align="center">
                            <template slot-scope="scope">
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    v-model="scope.row.text"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="Date" align="center"
                            ><template slot-scope="scope">{{
                                new Date(scope.row.createdAt)
                            }}</template></el-table-column
                        >
                        <div slot="empty" class="empty">
                            <span>Sorry, there's no relatived data</span>
                        </div>
                    </el-table>
                </div>
                <div class="pre-footer-container">
                    <div>
                        <el-pagination
                            @current-change="paginationChange"
                            :page-size="mailPaginationData.pageSize"
                            layout="prev, pager, next"
                            :total="mailPaginationData.total"
                            background
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { HostUrl, getMailData } from "@/api";

const DEAFULT_MAIL_DATA = [];
const DEAFULT_PAGINATION = {
    page: 1,
    pageSize: 20,
    total: 0,
};
export default {
    data() {
        return {
            type: "admin_letter",
            HostUrl: HostUrl,
            isFetching: true,
            filterType: "account",
            filterValue: "",
            filterUIValue: "",
            mailPaginationData: [],
            mailData: DEAFULT_MAIL_DATA,
            letterPaginationData: DEAFULT_PAGINATION,
        };
    },
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.syncData();
    },
    methods: {
        async syncData(page = 1) {
            const self = this;
            this.isFetching = true;
            let params = { page };
            if (this.filterValue && this.filterType) {
                const typeMap = {
                    "company id": "receiveUserId",
                    "customer user id": "senderUserId",
                };
                params = {
                    ...params,
                    [typeMap[self.filterType]]: self.filterValue,
                };
            }

            let res = await getMailData({ params });
            this.mailData = res?.data?.data?.list || DEAFULT_MAIL_DATA;
            this.mailPaginationData =
                res?.data?.data?.pagination || DEAFULT_PAGINATION;
            this.isFetching = false;
        },
        paginationChange(page) {
            this.syncData(page);
        },
        filterData() {
            this.filterValue = this.filterUIValue;
            this.syncData();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/admin.scss";
</style>